import Alpine from 'alpinejs';
// import intersect from '@alpinejs/intersect';
import intersectClass from 'alpinejs-intersect-class'

Alpine.plugin(intersectClass)

import Tooltip from "@ryangjchandler/alpine-tooltip";

Alpine.plugin(Tooltip);

window.Alpine = Alpine
Alpine.start();
// Alpine.plugin(intersect);

import './smoothscroll';

document.addEventListener('DOMContentLoaded', function() {


});
